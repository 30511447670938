import PostPreview from '../blog/preview';
import List from '../common/list';

export const PostSlice = ({ fields, className = '' }) => {
  return (
    <List
      component={PostPreview}
      items={fields}
      selector={(item) => item.post}
      className={className}
    />
  );
};
