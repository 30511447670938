import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { apiRoute } from '../../lib/api';
import { useTranslation } from '../../lib/i18n';
import { PRODUCT_PREVIEW_IMAGE_SIZE } from '../../lib/images';
import { setData } from '../../lib/storage';
import Carousel from '../common/carousel';
import Grid from '../common/grid';
import List from '../common/list';
import Loading from '../common/loading';
import ProductImage from '../product/image';
import { useUser } from '../user-context';

const DynamicAddToCart = dynamic(() => import('../cart/add-to-cart'));

const WithPromoCode = ({ primary, fields }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { checkout } = useUser();
  const { query } = router;
  const [validationCheck, setValidationCheck] = useState(undefined);

  const filter =
    primary.voucher_filter?.length > 0
      ? primary.voucher_filter[0].text.toLowerCase()
      : '';

  const item = fields[0];
  const line = checkout?.lines.find(
    (l) => l.variant.id === item.defaultVariant.id,
  );

  useEffect(() => {
    if (query.voucher) {
      if (query.voucher.toLowerCase().indexOf(filter) === 0) {
        apiRoute('/validate_voucher?voucher_code=' + query.voucher).then(
          (res) => {
            item.isAvailableForPurchase =
              (!line?.quantity || line.quantity === 0) && res.valid;

            if (res.valid) {
              setData(item.defaultVariant.id, query.voucher);
            }
            setValidationCheck(res);
          },
        );
      } else {
        item.isAvailableForPurchase = false;
        setValidationCheck({ valid: false });
      }
    } else {
      item.isAvailableForPurchase = !line?.quantity || line.quantity === 0;
      setValidationCheck({ valid: true });
    }
  }, [query.voucher, line?.quantity]);

  return (
    <div className='flex items-center flex-col gap-4'>
      <ProductImage
        size={PRODUCT_PREVIEW_IMAGE_SIZE}
        className={'w-full mb-3'}
        product={item}
        showDiscount={false}
      />
      {validationCheck !== undefined ? (
        <>
          <DynamicAddToCart
            product={item}
            className=''
            button
            subPriceText={primary.button_label}
            initialQuantity={1}
          />

          {query.voucher && (
            <div
              className={`flex items-center gap-1  ${validationCheck.valid ? 'text-green' : 'line-through'}`}
            >
              <span className='text-black'>{t('code')}</span>
              <span className='whitespace-nowrap	'>{query.voucher}</span>

              {validationCheck.valid && (
                <svg
                  id='Layer_1'
                  xmlns='http://www.w3.org/2000/svg'
                  version='1.1'
                  viewBox='0 0 110 135'
                  className='w-6 fill-current mt-[2px]'
                >
                  <path
                    className='st0'
                    d='M55,15c24.9,0,45,20.1,45,45s-20.1,45-45,45S10,84.9,10,60,30.1,15,55,15ZM70.5,44.2l-21.8,25.1-9.3-10.7c-2.6-3-7.1.9-4.5,3.9l11.5,13.3c1.2,1.4,3.3,1.4,4.5,0l24-27.7c2.6-3-1.9-6.9-4.5-3.9h0ZM82.6,32.4c-15.2-15.2-39.9-15.2-55.2,0-15.2,15.2-15.2,39.9,0,55.2s39.9,15.2,55.2,0c15.2-15.2,15.2-39.9,0-55.2Z'
                  />
                </svg>
              )}
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export const ProductsSlice = ({ primary, fields, className = '' }) => {
  if (primary.style === 'with_promo_code') {
    return <WithPromoCode primary={primary} fields={fields} />;
  }

  return (
    <List
      items={fields}
      Wrapper={primary.style === 'grid' ? Grid : Carousel}
      className={className}
      listName='cms products slice'
    />
  );
};
