const Fallback = ({ type, primary, fields }) => (
  <>
    <div className='font-bold'>{`Fallback: ${type}`}</div>
    {primary && (
      <div className='mt-2'>
        <pre>{JSON.stringify(primary, null, 1)}</pre>
      </div>
    )}
    {fields && (
      <div className='mt-2'>
        <pre>{JSON.stringify(fields, null, 1)}</pre>
      </div>
    )}
  </>
);

export const getBlock = (blockName, blocks) => {
  if (Object.prototype.hasOwnProperty.call(blocks, blockName)) {
    return blocks[blockName];
  }
  console.error(`${blockName} not found`);
  return Fallback;
};

export const getAllTexts = (textField) => {
  // iterate over every text fields and concat it
  return textField
    ? textField.reduce((total, { text }) => (total += text), '')
    : '';
};
