import { useEffect, useState } from 'react';
import Accordion from '../common/accordion';
import { RichText } from './rich-text';

export const AccordionSlice = ({ fields, className = '' }) => {
  const [identifier, setIdentifier] = useState('');

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      setIdentifier(hash.substr(2));
    }
  });
  return (
    <>
      {fields.map((field, index) => {
        const id = field.identifier ? field.identifier : false;
        return (
          <Accordion
            id={id}
            initialCollapsed={identifier !== id}
            className='mb-6'
            key={index}
            title={field.heading ? field.heading : ''}
          >
            {field.body1 && (
              <RichText className={className} elements={field.body1} />
            )}
          </Accordion>
        );
      })}
    </>
  );
};
