import Link from 'next/link';
import fbSvg from '../../img/facebook_dark.svg';
import instagramSvg from '../../img/insta_dark.svg';
import websiteSvg from '../../img/website.svg';

const SocialMediaItem = ({ img, href = '', className = '', label = '' }) => (
  <li className='my-3 md:my-0'>
    <Link
      href={href}
      className={`${className} flex items-center`}
      aria-label={href}
      target='_blank'
      rel='noopener noreferrer'
    >
      <img alt={label} src={img} className='w-10' />
      {label && <span className='pl-2 text-sm font-bold'>{label}</span>}
    </Link>
  </li>
);

const extractUsername = (url) => {
  return url
    .split('/')
    .filter((e) => e.length)
    .slice(-1)[0];
};

const extractDomain = (url) => {
  return url.replace('https://', '').replace('http://', '').replace('www.', '');
};

export const LinksSlice = ({ primary }) => {
  return (
    <ul className='justify-between md:flex'>
      {primary.website?.url && (
        <SocialMediaItem
          img={websiteSvg}
          href={primary.website.url}
          label={extractDomain(primary.website.url)}
        />
      )}
      {primary.facebook_url?.url && (
        <SocialMediaItem
          img={fbSvg}
          href={primary.facebook_url.url}
          label={extractUsername(primary.facebook_url.url)}
        />
      )}
      {primary.instagram_url?.url && (
        <SocialMediaItem
          img={instagramSvg}
          href={primary.instagram_url.url}
          label={extractUsername(primary.instagram_url.url)}
        />
      )}
    </ul>
  );
};
