import Image from 'next/legacy/image';
import { Container } from '../../components/layout/container';
import Layout from '../../components/layout/layout';
import { useTranslation } from '../../lib/i18n';
import {
  calc4to3ImageProps,
  img4to3Loader,
  POST_DETAIL_IMAGE_WIDTH,
} from '../../lib/images';
import PostMeta from '../blog/meta';
import { ShareButton } from '../common/share';
import { Video } from '../common/video';
import LightBoxContextProvider from '../lightbox-context';
import { Body } from './body';
import { RichText } from './rich-text';

const Inner = ({
  content,
  preview,
  children = null,
  leftAlign = true,
  ldJson = null,
}) => {
  const { t } = useTranslation();
  const bgColor = content.metadata?.['bg-color'] ?? '';
  const fgColor = content.metadata?.['fg-color'] ?? '';

  const isPromo = content._meta.tags.indexOf('Promo') > -1;

  return (
    <Layout
      title={content.seo_title}
      description={content.seo_text}
      ogImage={
        content.social_media_preview
          ? `${content.social_media_preview.url}&w=1200`
          : content.heading_image
            ? `${content.heading_image.url}&w=1200`
            : null
      }
      canonicalUrl={content?.canonical_link1?.url}
      preview={preview}
      className={`${content._meta?.uid}`}
      robots={
        typeof content.search_engines == 'undefined' || content.search_engines
          ? 'all'
          : 'noindex'
      }
      ldJson={ldJson}
    >
      <style>
        {`:root {
      --edvin-title: ${fgColor};
      --edvin-background: ${bgColor};
    }`}
      </style>

      {content.heading_image ? (
        <div
          className={`detail-offset w-full lg:bg-varBackground mb-7 ${isPromo ? 'lg:mb-12' : ''}`}
        >
          <div className='relative mx-auto max-w-6xl '>
            {content._meta.type === 'blog_post' ? (
              <ShareButton className='absolute bottom-4 left-0 hidden pl-6 text-varTitle lg:block 6xl:pl-0'>
                <span className='pr-2 text-base font-bold uppercase'>
                  {t('share')}
                </span>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12.8 9.6C11.84 9.6 10.96 10 10.4 10.72L6.32 8.72C6.4 8.48 6.48 7.84 6.32 7.36L10.4 5.28C10.96 6 11.84 6.4 12.8 6.4C14.56 6.4 16 4.96 16 3.2C16 1.44 14.56 0 12.8 0C11.04 0 9.6 1.44 9.6 3.2C9.6 3.44 9.6 3.68 9.68 3.84L5.6 5.92C5.04 5.2 4.16 4.8 3.2 4.8C1.44 4.8 0 6.24 0 8C0 9.76 1.44 11.2 3.2 11.2C4.16 11.2 5.04 10.8 5.6 10.08L9.68 12.08C9.6 12.32 9.6 12.56 9.6 12.8C9.6 14.56 11.04 16 12.8 16C14.56 16 16 14.56 16 12.8C16 11.04 14.56 9.6 12.8 9.6ZM12.8 1.6C13.68 1.6 14.4 2.32 14.4 3.2C14.4 4.08 13.68 4.8 12.8 4.8C11.92 4.8 11.2 4.08 11.2 3.2C11.2 2.32 11.92 1.6 12.8 1.6ZM3.2 9.6C2.32 9.6 1.6 8.88 1.6 8C1.6 7.12 2.32 6.4 3.2 6.4C4.08 6.4 4.8 7.12 4.8 8C4.8 8.88 4.08 9.6 3.2 9.6ZM12.8 14.4C11.92 14.4 11.2 13.68 11.2 12.8C11.2 11.92 11.92 11.2 12.8 11.2C13.68 11.2 14.4 11.92 14.4 12.8C14.4 13.68 13.68 14.4 12.8 14.4Z'
                    fill='currentColor'
                  />
                </svg>
              </ShareButton>
            ) : (
              <></>
            )}

            <div className='flex flex-col-reverse justify-items-stretch lg:flex-row lg:items-center'>
              {content.title || content.lead ? (
                <div className='pl-6 pr-6 lg:w-1/2 lg:pr-10 6xl:pl-0'>
                  {content.title && (
                    <h1 className='my-6 text-center lg:my-6 lg:block lg:text-left lg:text-5xl lg:text-varTitle'>
                      {content.title}
                    </h1>
                  )}

                  {content.lead && (
                    <RichText
                      className={`text-lg font-bold uppercase text-black lg:text-varTitle ${leftAlign ? '' : 'text-center lg:text-left'}`}
                      elements={content.lead}
                    />
                  )}
                </div>
              ) : (
                <></>
              )}
              {content.heading_video ? (
                <div className='relative flex-grow text-center lg:w-1/2'>
                  <Video
                    video={content.heading_video}
                    image={content.heading_image}
                    // className='mx-auto max-w-2xl'
                    // width={POST_DETAIL_IMAGE_WIDTH}
                    preload
                  />
                </div>
              ) : (
                <div className='relative flex-grow text-center lg:w-1/2'>
                  <Image
                    loader={img4to3Loader}
                    {...calc4to3ImageProps(
                      content.heading_image,
                      POST_DETAIL_IMAGE_WIDTH,
                    )}
                    priority
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : content.title || content.lead ? (
        <Container wide className='mb-6 mt-16'>
          <div
            className={`max-w-2xl ${leftAlign ? '' : 'mx-auto text-center'}`}
          >
            {content.title && <h1 className='text-6xl'>{content.title}</h1>}

            {content.lead && (
              <RichText
                className='mt-3 text-lg font-bold'
                elements={content.lead}
              />
            )}
          </div>
        </Container>
      ) : (
        <></>
      )}
      {content._meta.type === 'blog_post' ? (
        <Container>
          <PostMeta post={content} link={true} />
        </Container>
      ) : (
        <></>
      )}
      <Body
        // @ts-ignore
        hasFilters={content.hasFilter}
        bodyElement={content.body}
        leftAlign={leftAlign}
        gapClassName={
          content._meta.uid === 'home'
            ? 'mt-24'
            : isPromo
              ? 'mt-5 text-center'
              : 'mt-12'
        }
      >
        {children}
      </Body>
    </Layout>
  );
};

const CmsDetail = (props) => (
  <LightBoxContextProvider>
    <Inner {...props} />
  </LightBoxContextProvider>
);

export default CmsDetail;
