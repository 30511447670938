import { Video } from '../common/video';

export const VideoSlice = ({
  primary: { youtube_url: video, thumbnail },
  className = '',
}) => {
  // check for optional values
  if (!video) {
    return <></>;
  }

  return (
    <div className={className}>
      <Video video={video} image={thumbnail} className='max-w-xl' />
    </div>
  );
};
