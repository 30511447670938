import { useEffect, useState } from 'react';
import dropDownSvg from '../../img/dropdown.svg';

const Accordion = ({
  title,
  children,
  className = '',
  icon = null,
  id = false,
  multilineHeading = false,
  initialCollapsed = true,
  classNameBody = 'pb-2',
  positionClassName = 'max-w-2xl mx-auto',
  classNameTitle = 'font-bold',
  mobileOnly = false,
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  useEffect(() => {
    if (collapsed !== initialCollapsed) {
      setCollapsed(initialCollapsed);
    }
  }, [initialCollapsed]);

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCollapsed(!collapsed);
  };

  const props = {
    className: `${positionClassName} flex flex-row ${
      multilineHeading ? 'items-start' : 'items-center'
    } text-left pb-2 mb-2 border-b border-0.3 ${mobileOnly ? 'lg:hidden' : ''}`,
    onClick,
    href: '#',
    role: 'button',
  };

  if (id) {
    props.id = id;
  }

  return (
    <div className={`${className}`}>
      <a {...props}>
        {icon && <img alt='' className='mr-5 block' src={icon}></img>}
        <span className={`${classNameTitle} mr-3 flex-grow uppercase`}>
          {title}
        </span>
        <img
          alt=''
          src={dropDownSvg}
          className={`transform duration-300 ${multilineHeading && 'mt-2'} ${
            !collapsed ? '-rotate-180' : ''
          }`}
        />
      </a>

      <div
        className={
          collapsed
            ? mobileOnly
              ? 'hidden lg:block'
              : 'hidden'
            : `block ${classNameBody}`
        }
      >
        {children}
      </div>

      <div className={positionClassName}></div>
    </div>
  );
};

export default Accordion;
