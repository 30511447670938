import { useEffect, useRef, useState } from 'react';
import { BASE_URL } from '../../lib/config';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { useTranslation } from '../../lib/i18n';
import { trackEvent } from '../../lib/tracking';

const getCurrent = (t) => ({
  title: document?.title || t('homeTitle'),
  url: window?.location?.href || 'https://edvinweine.ch',
});

const ShareDialog = ({ title, url }) => {
  return (
    <div className='absolute left-0 top-8 z-10 flex max-w-xl bg-white px-3 py-3 shadow-lg'>
      <FacebookShareButton className={'mr-3'} url={url} quote={title}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <WhatsappShareButton
        className={'mr-3'}
        url={url}
        title={title}
        separator=':: '
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <TwitterShareButton className={'mr-3'} url={url} title={title}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <LinkedinShareButton className={'mr-3'} url={url}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>

      <PinterestShareButton
        className={'mr-3'}
        url={url}
        media={`${BASE_URL}/static/edvin-share.jpg`}
      >
        <PinterestIcon size={32} round />
      </PinterestShareButton>

      <RedditShareButton
        className={'mr-3'}
        url={url}
        title={title}
        windowWidth={660}
        windowHeight={460}
      >
        <RedditIcon size={32} round />
      </RedditShareButton>

      <EmailShareButton url={url} subject={title} body={title}>
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  );
};

export const ShareButton = ({ className = '', children = undefined }) => {
  const [showShareDialog, setShowShareDialog] = useState(false);
  const { t } = useTranslation();

  const ref = useRef(null);

  useEffect(() => {
    const onClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowShareDialog(false);
      }
    };
    document.addEventListener('mouseup', onClick);
    return () => {
      document.removeEventListener('mouseup', onClick);
    };
  }, []);

  const onClick = async () => {
    try {
      if (!showShareDialog) {
        trackEvent({
          category: 'share',
          action: 'click',
          label: document?.title,
        });
      }
      if (navigator.share) {
        await navigator.share(getCurrent(t));
        return;
      }
    } catch (e) {
      if (e.name === 'AbortError') {
        return;
      }
    }
    // fallback
    setShowShareDialog(!showShareDialog);
  };

  return (
    <div className={className} ref={ref}>
      <a
        aria-label={t('Teilen')}
        onClick={onClick}
        role='button'
        className='flex cursor-pointer flex-row items-center'
      >
        {children}
      </a>
      {showShareDialog && <ShareDialog {...getCurrent(t)} />}
    </div>
  );
};
