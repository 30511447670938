import dynamic from 'next/dynamic';
import { createContext, useContext, useState } from 'react';

const DynamicLightbox = dynamic(() => import('./common/lightbox'));

// @ts-ignore
export const LightBoxContext = createContext();

const LightBoxContextProvider = (props) => {
  const [state, setState] = useState({
    index: -1,
    images: [],
  });

  const optimizeUrl = (i) =>
    `${i.url}${
      i.url.indexOf('?') > -1 ? '&' : '?'
    }w=1536&h=4000&fit=clip&auto=compress,format`;

  const setIndex = (index) => {
    setState({ ...state, index: index });
  };

  const setUrl = (url) => {
    const foundUrl = state.images.find((i) => i.indexOf(url) > -1);
    if (foundUrl) {
      setState({ ...state, index: state.images.indexOf(foundUrl) });
    } else {
      setState({
        images: [optimizeUrl({ url }), ...state.images],
        index: 0,
      });
    }
  };

  const setImages = (images) => {
    setState({ ...state, images: images.map(optimizeUrl) });
  };

  return (
    <LightBoxContext.Provider
      value={{
        setUrl,
        setImages,
        optimizeUrl,
        currentImages: state.images,
        currentIndex: state.index,
        setIndex,
      }}
    >
      {state.index > -1 && state.images.length > 0 && <DynamicLightbox />}
      {props.children}
    </LightBoxContext.Provider>
  );
};
export default LightBoxContextProvider;

export const useLightBox = () => useContext(LightBoxContext);
