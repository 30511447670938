import React from 'react';
import { slices } from '.';
import { Container, MaxContainer } from '../layout/container';
import { SliceContainer } from './slice-container';
import { getBlock } from './utils';

const BaseBody = ({
  bodyElement,
  hasFilters = false,
  children = null,
  leftAlign = false,
  gapClassName = 'mt-12',
}) => {
  const Wrapper = hasFilters ? MaxContainer : React.Fragment;

  return (
    <Wrapper>
      {bodyElement &&
        bodyElement.map((block, index) => {
          const Block = getBlock(block.type, slices);
          return (
            <SliceContainer
              primary={block.primary}
              key={index}
              blockType={block.type}
              leftAlign={leftAlign}
              rightAlign={hasFilters}
              className={index > 0 ? gapClassName : ''}
              // @ts-ignore
              Wrapper={block.type === 'filterwithimages' ? 'div' : Container}
            >
              <Block
                type={block.type}
                primary={block.primary}
                fields={block.fields}
                block={block}
              />
            </SliceContainer>
          );
        })}
      {children && <>{children}</>}
    </Wrapper>
  );
};

export const Body = React.memo(BaseBody);
