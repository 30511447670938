import Image from 'next/legacy/image';
import { useEffect } from 'react';
import {
  calcImageProps,
  CAROUSEL_SINGLE_IMAGE_SIZE,
  prismicLoader,
} from '../../lib/images';
import { ImageCarousel } from '../common/image-carousel';
import { useLightBox } from '../lightbox-context';

export const ImageSlice = ({ primary, fields: images }) => {
  const { setImages } = useLightBox();

  const className =
    primary.style === 'original' ? 'flex items-center flex-col' : 'rounded-lg';

  useEffect(() => {
    setImages(images);
  }, []);

  if (images.length == 1) {
    const img = images[0];

    return (
      <Image
        className={className}
        loader={prismicLoader}
        {...img.dimensions}
        {...(primary.style === 'original'
          ? {
              height: img.dimensions.height / 2,
              width: img.dimensions.width / 2,
              src: img.url,
            }
          : calcImageProps(img, CAROUSEL_SINGLE_IMAGE_SIZE))}
      />
    );
  }

  return <ImageCarousel images={images} />;
};
