import { AccordionSlice } from './accordion';
import { ButtonSlice } from './button-slice';
import { CollectionsSlice } from './collections-slice';
import { CompentitionSlice } from './competition-slice';
import { ContactSlice } from './contact-slice';
import { FilterWithImages } from './filter-with-images-slice';
import { ImageSlice } from './images-slice';
import { LinksSlice } from './links-slice';
import { NewsletterSlice } from './newsletter-slice';
import { PersonSlice } from './person-slice';
import { PostSlice } from './posts-slice';
import { ProductsSlice } from './products-slice';
import { TeaserSlice } from './teaser-slice';
import { TextSlice } from './text-slice';
import { VideoSlice } from './video-slice';
import { WineCalculatorSlice } from './wine-calculator-slice';

export const slices = {
  images: ImageSlice,
  text: TextSlice,
  video: VideoSlice,
  posts: PostSlice,
  products: ProductsSlice,
  accordion: AccordionSlice,
  button: ButtonSlice,
  newletter: NewsletterSlice,
  kontak: ContactSlice,
  teasers: TeaserSlice,
  competition: CompentitionSlice,
  weinrechner: WineCalculatorSlice,
  links: LinksSlice,
  collections: CollectionsSlice,
  person: PersonSlice,
  filterwithimages: FilterWithImages,
};
