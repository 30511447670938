import Image from 'next/legacy/image';
import Link from 'next/link';
import { calcTitleImageProps, img4to3Loader } from '../../lib/images';
import Carousel from '../common/carousel';
import { FilterContainer } from '../filter/filter-container';

export const FilterWithImages = ({ primary, fields }) => {
  return (
    <FilterContainer
      Wrapper={Carousel}
      className='mt-6 lg:mt-10'
      defaultResults={primary.products}
    >
      <div className='lg:ml-10'>
        <Carousel
          className='filter-with-images-carousel md:mb-6'
          boarders={false}
          navigation={false}
          pagination={true}
          // @ts-ignore
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
          }}
          spaceBetween={10}
        >
          {fields.map((field, index) => (
            <Link href={field.link} key={index} legacyBehavior>
              <div
                className={`rounded-lg-img relative cursor-pointer rounded-lg`}
              >
                <Image
                  loader={img4to3Loader}
                  priority={true}
                  {...calcTitleImageProps(field.image, 512)}
                />
                <div className='absolute bottom-0 left-0 right-0 top-0 rounded-lg bg-gradient-to-t from-black to-50% opacity-70'></div>
                <div className='absolute bottom-10 left-0 right-0 mx-auto max-w-sm px-4 text-center'>
                  {index === 0 ? (
                    <h1 className='mb-2 text-2xl text-white lg:text-3xl'>
                      {field.title}
                    </h1>
                  ) : (
                    <h2 className='mb-2 text-2xl text-white lg:text-3xl'>
                      {field.title}
                    </h2>
                  )}

                  <a
                    className={`flex items-center justify-center px-10 font-bold uppercase text-white`}
                  >
                    {field.callToAction}{' '}
                    <svg
                      className='ml-2'
                      width='14'
                      height='12'
                      viewBox='0 0 14 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M0.417969 6.6125H10.0867C10.2661 6.61437 10.4098 6.76062 10.4086 6.94062C10.4073 7.02937 10.3705 7.11437 10.3055 7.175L6.44297 11.0375L7.32422 11.9187L13.2492 5.9875L7.33047 0.0687485L6.44922 0.949998L10.3117 4.8125C10.443 4.935 10.4498 5.14062 10.3273 5.27187C10.2667 5.33687 10.1817 5.37437 10.093 5.375H0.417969V6.6125Z'
                        fill='white'
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </Link>
          ))}
        </Carousel>
      </div>
    </FilterContainer>
  );
};
