import Image from 'next/legacy/image';
import { useState } from 'react';
import dropDownSvg from '../../img/dropdown.svg';
import { useTranslation } from '../../lib/i18n';
import { PERSON_SIZE, calcImageProps, prismicLoader } from '../../lib/images';
import List from '../common/list';
import { RichText } from './rich-text';

export const PersonSlice = ({
  primary: { heading, picture, name, job_title },
  fields,
  className = '',
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCollapsed(!collapsed);
  };

  return (
    <>
      {heading && <RichText className='striking-headings' elements={heading} />}
      <div className='items-end gap-6 md:flex'>
        <Image
          className='rounded-lg'
          loader={prismicLoader}
          {...calcImageProps(picture, PERSON_SIZE)}
        />
        <div>
          <div className='max-word-spacing max-w-xs text-5xl font-bold uppercase'>
            {name}
          </div>
          <div>{job_title}</div>

          <a
            className='mt-5 flex cursor-pointer flex-row font-bold'
            onClick={onClick}
          >
            <span className={`mr-3 uppercase`}>
              {t('Aktuelle Lieblingsweine')}
            </span>
            <img
              alt=''
              src={dropDownSvg}
              className={`transform duration-300 ${
                collapsed ? '' : 'rotate-180 transform'
              }`}
            />
          </a>
        </div>
      </div>

      <div className={collapsed ? 'hidden' : `block pt-7`}>
        <List
          items={fields}
          className={className}
          listName='cms person slice'
        />
      </div>
    </>
  );
};
